import './service.css'
import {BiSolidPencil,  BiCamera, BiCodeAlt} from 'react-icons/bi'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Service = () => {
  useEffect(()=> {

   AOS.init({duration:2000})

  },[])


  return (
    <div className='services p-5' id='services'>
        <div className='myserviceText pt-5'>
            <h3>MY <span>Certifications</span></h3><br />
            <hr />
        </div>

        <div className="container text-center mt-5" >
            <div className="row">
                <div className='col-12 col-md-6 col-lg-4 p-4' data-aos='fade-right'>
                     <div className="border service">
                        <p className='serviceIcon' ><span className='border p-2' ><BiSolidPencil /></span></p>
                        <h5 className='mt-4'>WEB DESIGN</h5>
                        <p>
                           escriptiondescriptio
                           ndescriptiondescriptiond
                           escriptiondescription
                        </p>
                     </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4 p-4' data-aos='zoom-in'>
                     <div className="border service">
                        <p className='serviceIcon' ><span className='border p-2' ><BiCamera /></span></p>
                        <h5 className='mt-4'>PHOTOGRAPHY</h5>
                        <p>
                           escriptiondescriptio
                           ndescriptiondescriptiond
                           escriptiondescription
                        </p>
                     </div>
                </div>

                <div className='col-12 col-md-6 col-lg-4 p-4' data-aos='fade-left'>
                     <div className="border service">
                        <p className='serviceIcon' ><span className='border p-2' ><BiCodeAlt /></span></p>
                        <h5 className='mt-4'>WEB DEVELOPMENT</h5>
                        <p>
                           escriptiondescriptio
                           ndescriptiondescriptiond
                           escriptiondescription
                        </p>
                     </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Service
import './home.css'
import Typewriter from 'typewriter-effect'
import {BsLinkedin, BsWhatsapp} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'


const Home = () => {
  return (
    <div className='w-100 home' >
        <div className="container">
            <div className="row">
                <div style={{padding:'20px'}} className='col-12 col-md-6 col-lg-6 homeTextPart'>
                    <div>
                        <h3 style={{marginTop:'50px'}} className='welcome'>WELCOME !</h3>
                        <h1 style={{color:' rgb(235, 14, 187)', fontWeight:'bolder'}}>
                            <Typewriter 
                             onInit={(typewriter) => {
                                typewriter
                                    .typeString("I AM WEB DEVELOPER")
                                    .pauseFor(1000)
                                    .deleteAll()
                                    .typeString("I AM WEB DESIGNER")
                                    .start();
                                    
                             }}
                            />
                        </h1>
                        
                        
                        
                        
                        <div className="icons" style={{marginTop:'35px', fontSize:'30px'}}>
                           <a href="https://www.linkedin.com/in/prem-kumar-01a44623b/"className='icon' style={{padding:'10px', color:"black"}}><BsLinkedin /></a>
                           <a href="https://github.com/Prem-13"className='icon' style={{padding:'10px', color:"black"}}><FaGithub /></a>  
                           <a href="https://wa.link/u1zlp3"className='icon' style={{padding:'10px', color:"black"}}><BsWhatsapp /></a>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-6'>
                <p className='details'>
                            I'am a front-end developer 
                            with 6 months of intern experience.
                            My expertise is to create front-end design
                             for a website.

                        </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home.jsx";
import About from './components/About/About.jsx'
import Service from './components/services/Service.jsx'
import Projects from './components/projects/Projects.jsx'
import Contact from './components/contacts/Contact.jsx'
import Footer from './components/footer/Footer.jsx'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Service />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
